<template>
  <div class="container">
    <div class="top" id="bloc-0">
      <voice-header></voice-header>
    </div>
    
    <div class="main sound-effect-page">
      <div class="hero-section">
        <div class="hero-content">
          <h1>{{ $t('soundeffect.hero_title') }}</h1>
          <p class="hero-subtitle">{{ $t('soundeffect.hero_subtitle') }}</p>
        </div>
      </div>
      
      
      
      <!-- 试听区域 -->
      <div class="sound-samples-wrapper">
        <div class="sound-samples">
          <h2 class="sound-samples-title">{{ $t('soundeffect.samples_title') }}</h2>
          
          <!-- 分类导航栏 -->
          <div class="categories-tabs">
            <button 
              v-for="category in categories" 
              :key="category.id"
              :class="['category-tab', { active: currentCategory === category.id }]"
              :data-category="category.id"
              @click="currentCategory = category.id"
            >
              {{ $t(`soundeffect.category_${category.id}`) }}
            </button>
          </div>
          
          <!-- 音效卡片网格 -->
          <div class="sound-cards-grid">
            <div 
              v-for="sample in currentSamples" 
              :key="sample.id"
              class="sound-card"
              :data-category="currentCategory"
              @click="playSample(sample)"
              :class="{ 'is-playing': currentPlaying === sample.id }"
            >
              <div class="sound-card-content">
                <h3 class="sound-title">
                  <span class="emoji">{{ sample.emoji }}</span>
                  {{ $t(`soundeffect.sample_${sample.id}`) }}
                </h3>
                <button 
                  class="play-btn"
                  :data-category="currentCategory"
                  :class="{ 'loading': isLoadingSample === sample.id }"
                >
                  <!-- 加载中状态 -->
                  <svg v-if="isLoadingSample === sample.id" class="loading-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                  </svg>
                  <!-- 播放/暂停状态 -->
                  <svg v-else-if="currentPlaying === sample.id" class="pause-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                  </svg>
                  <!-- 默认状态 -->
                  <svg v-else class="play-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M8 5v14l11-7z"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-container" style="max-width: min(800px, 100% - 2rem);">
        <div class="input-container">
          <div class="title-group">
            <h2 class="generator-title">{{ $t('soundeffect.generator_title') }}</h2>
            <p class="generator-subtitle">{{ $t('soundeffect.generator_subtitle') }}</p>
          </div>

          <!-- 标签展示部分 -->
          <div class="prompt-tags">
            <button 
              v-for="(prompt, index) in promptExamples" 
              :key="index"
              class="prompt-tag"
              @click="selectPrompt(prompt.key)"
            >
              {{ prompt.display }}
            </button>
          </div>

          <!-- 然后是文本框 -->
          <div class="textarea-wrapper">
            <textarea 
              v-model="description"
              class="effect-input"
              maxlength="100"
              rows="3"
            ></textarea>
            <div class="char-counter" :class="{ 'near-limit': description.length > 80 }">
              {{ description.length }}/100
            </div>
          </div>

          <div class="controls">
            <button 
              class="generate-btn" 
              @click="generateSound"
              :disabled="isGenerating"
            >
              <div class="generate-btn-content">
                <svg v-if="isGenerating" class="loading-icon" viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
                <span v-else>{{ $t('soundeffect.btn_generate') }}</span>
              </div>
            </button>
            <!-- button class="settings-btn" @click="openSettings">Settings</button -->
          </div>
        </div>

        <div class="generated-sounds" v-if="showAudioControls">
          <div class="sound-item">
            <div class="sound-info">
              <button class="play-button" @click="playSound">
                <span v-if="!isPlayLoading">
                  <svg v-if="!isPlaying" class="play-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M8 5v14l11-7z"/>
                  </svg>
                  <svg v-else class="pause-icon" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="currentColor" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/>
                 </svg>
                </span>
                <svg v-else class="loading-icon" viewBox="0 0 24 24" width="20" height="20">
                  <path fill="currentColor" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                </svg>
              </button>
              <span class="generation-title">Generation {{ generationCount }}</span>
              
              <div class="progress-container" ref="progressContainer" @click="seek" @mousedown="startDrag">
                <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
                <div class="progress-handle" :style="{ left: `${progress}%` }"></div>
              </div>
              
              <span class="time-display">{{ formatTime(audioCurrentTime) }} / {{ formatTime(audioDuration) }}</span>
            </div>
            <button class="download-button" 
                @click="handleDownload" 
                :disabled="!generatedSound || isDownloading"
                :class="{ 'downloading': isDownloading }" 
                > {{ isDownloading ? $t('soundeffect.btn_downloading') : $t('soundeffect.btn_download') }}
              </button>
          </div>
        </div>
      </div>
      <audio ref="audio_main" style="display: none;">
      </audio>

<!-- Features Section -->
<div class="advantages-section">
        <div class="advantages-container">
          <h2 class="advantages-title">{{ $t('soundeffect.advantages_title') }}</h2>
          <div class="advantages-grid">
            <div class="advantage-card">
              <div class="advantage-icon">⚡</div>
              <h3>{{ $t('soundeffect.advantage_1_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_1_desc') }}</p>
            </div>
            
            <div class="advantage-card">
              <div class="advantage-icon">🎵</div>
              <h3>{{ $t('soundeffect.advantage_2_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_2_desc') }}</p>
            </div>
            
            <div class="advantage-card">
              <div class="advantage-icon">🎯</div>
              <h3>{{ $t('soundeffect.advantage_3_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_3_desc') }}</p>
            </div>
            
            <div class="advantage-card">
              <div class="advantage-icon">✅</div>
              <h3>{{ $t('soundeffect.advantage_4_title') }}</h3>
              <p>{{ $t('soundeffect.advantage_4_desc') }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- FAQ Section -->
      <div class="faq-section">
        <h2>{{ $t('soundeffect.faq_title') }}</h2>
        <div class="faq-grid">
          <div 
            v-for="(faq, index) in faqs" 
            :key="index"
            class="faq-item"
            :class="{ 'active': faq.isOpen }"
          >
            <div class="faq-question" @click="toggleFaq(index)">
              <h3>{{ $t(`soundeffect.faqs.${index}.question`) }}</h3>
              <svg 
                class="arrow-icon" 
                viewBox="0 0 24 24" 
                width="24" 
                height="24"
                :class="{ 'rotated': faq.isOpen }"
              >
                <path fill="currentColor" d="M7 10l5 5 5-5H7z"/>
              </svg>
            </div>
            <div class="faq-answer" v-show="faq.isOpen">
              <p>{{ $t(`soundeffect.faqs.${index}.answer`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import axios from 'axios'
import VoiceHeader from '../components/VoiceHeader.vue'
import { handleGoogleAuth } from '../utils/auth' 
import { reportError } from '../utils/errorReporter'

const VoiceFooter = defineAsyncComponent(() => import('../components/VoiceFooterIndex.vue'))
const PopupModal = defineAsyncComponent(() => import('@/components/PopupModal.vue'))
const PopupDownload = defineAsyncComponent(() => import('@/components/PopupDownload.vue'))
const GoogleSignInModal = defineAsyncComponent(() => import('@/components/GoogleSignInModal.vue'))

const api_host = 'https://tiktokvoice.net'

export default {
  name: 'soundseffect',
  components: {
    VoiceHeader,
    VoiceFooter,
    PopupModal,
    PopupDownload,
    GoogleSignInModal
  },
  data() {
    return {
      description: '',
      generatedSound: null,
      isGenerating: false,
      showAudioControls: false,
      isPlaying: false,
      isDownloading: false,
      isPlayLoading: false,
      email: '',
      promptExamples: [
        // Nature
        { key: 'Rain', display: 'Rain' },
        { key: 'soundeffect.prompt_ocean waves', display: 'Ocean Waves' },
        { key: 'soundeffect.prompt_flowing water', display: 'Flowing Water' },
        
        // Special Effects
        { key: 'Fireworks', display: 'Fireworks' },
        { key: 'soundeffect.prompt_glass shattering', display: 'Glass Shattering' },
        { key: 'soundeffect.prompt_magic spell', display: 'Magic Spell' },
        
        // Instruments
        { key: 'Piano', display: 'Piano' },
        { key: 'soundeffect.prompt_electric guitar', display: 'Electric Guitar' },
        { key: 'Violin', display: 'Violin' },
        
        // Human Sounds
        { key: 'soundeffect.prompt_baby laughing', display: 'Baby Laughing' },
        { key: 'Clapping', display: 'Clapping' },
        { key: 'Celebrate', display: 'Celebrate' },
        
        // Ambient
        { key: 'Typing', display: 'Typing' },
        { key: 'soundeffect.prompt_noisy restaurant', display: 'Noisy Restaurant' },
        { key: 'soundeffect.prompt_doorbell ring', display: 'Doorbell Ring' }
      ],
      faqs: [
        {
          question: 'What is a Text to Sound Effect Generator?',
          answer: 'Our text to sound effect generator is a cutting-edge tool that uses advanced artificial intelligence to convert text descriptions into custom audio. The text to sound effect system combines natural language processing with audio synthesis to produce high-quality sound effects, making it ideal for content creators, game developers, and audio professionals. The text to sound effect generator eliminates the need for expensive sound libraries or recording sessions.',
          isOpen: false
        },
        {
          question: 'How does our AI Sound Effect Generator work?',
          answer: 'Type a description, and our AI Sound Effect Generator analyzes it using natural language processing to understand the desired sound characteristics. The system considers multiple factors including frequency, amplitude, duration, and spatial elements to generate unique audio outputs. Our AI Sound Effect Generator can process complex descriptions and create nuanced sounds that match your specific requirements.',
          isOpen: false
        },
        {
          question: 'What makes our AI Sound Effect Generator unique?',
          answer: 'Our AI Sound Effect Generator stands out through its combination of speed, quality, and versatility. It offers instant generation, professional-grade audio output, and extensive customization options. The AI model is trained on a vast dataset of high-quality samples, ensuring realistic and accurate sound effects. Additionally, our AI Sound Effect Generator provides unlimited generations and seamless integration with popular audio software.',
          isOpen: false
        },
        {
          question: 'How to write effective prompts for AI sound generation?',
          answer: 'For optimal results with our AI Sound Effect Generator, start with clear, specific descriptions. Include details about intensity, duration, and context. For example, instead of "rain", try "heavy rain on a metal roof with distant thunder". Consider adding environmental context, material properties, and emotional qualities. Our AI Sound Effect Generator understands both simple requests and complex audio concepts, allowing for precise control over the generated sounds.',
          isOpen: false
        },
        {
          question: 'How to best use AI sound effects in video content?',
          answer: 'Use our AI Sound Effect Generator to enhance video content by emphasizing key moments with custom sound effects. Layer multiple effects to create rich soundscapes that maintain viewer engagement. The AI Sound Effect Generator is particularly effective for creating consistent audio branding, enhancing storytelling, and adding professional polish to your videos. Consider using different sound variations for transitions, actions, and ambient backgrounds.',
          isOpen: false
        },
        {
          question: 'How to utilize AI sound effects in game development?',
          answer: 'Our AI Sound Effect Generator offers powerful solutions for game audio design. Create procedural variations to avoid repetition in common actions, generate adaptive audio that responds to game states, and design unique ambient soundscapes for different environments. The AI Sound Effect Generator enables rapid prototyping and iteration, making it ideal for both indie developers and large studios. Use it to create consistent sound packages for specific game features or environments.',
          isOpen: false
        },
        {
          question: 'How to create effective white noise and ambient sounds?',
          answer: 'Define the atmosphere you want, and our AI Sound Effect Generator will create layered, full-spectrum sounds. For natural environments, specify elements like wind intensity or water movement. For urban spaces, include details about crowd density and architectural acoustics. The AI Sound Effect Generator excels at creating seamless loops and subtle variations, perfect for long-duration ambient sounds. Adjust characteristics for specific uses like sleep aids, focus enhancement, or environmental masking.',
          isOpen: false
        },
        {
          question: 'What types of sound effects can I create?',
          answer: 'Our AI Sound Effect Generator can create a wide range of sound effects, including nature sounds (rain, wind, animals), musical elements (instruments, rhythms), human sounds (footsteps, voices), mechanical effects (machines, vehicles), fantasy/sci-fi sounds (magic, futuristic technology), impact sounds (explosions, collisions), and ambient atmospheres. Each category can be extensively customized through detailed prompts and parameter adjustments in the AI Sound Effect Generator.',
          isOpen: false
        },
        {
          question: 'Are the AI-generated sound effects royalty-free?',
          answer: 'Yes, all sounds from our AI Sound Effect Generator are completely royalty-free and include a commercial license. You can use them in any project without additional fees or attribution requirements. This includes commercial applications, multiple projects, and worldwide usage. Our AI Sound Effect Generator provides full ownership rights, allowing you to modify and distribute the sounds as needed for your creative work.',
          isOpen: false
        },
        {
          question: 'What is the quality of AI-generated sound effects?',
          answer: 'Our AI Sound Effect Generator produces professional-grade sound effects that meet industry standards. The output includes high-resolution audio up to 192kHz/24-bit, full frequency spectrum coverage, and carefully controlled dynamic range. Each sound generated by our AI Sound Effect Generator undergoes automatic quality checks and is compatible with all major digital audio workstations. The results are suitable for professional media production, game development, and broadcast use.',
          isOpen: false
        }
      ],
      generationCount: 0,
      currentCategory: 'nature',
      currentPlaying: null,
      categories: [
        { id: 'nature', name: 'Nature Sound' },
        { id: 'special', name: 'Special Effects' },
        { id: 'instrument', name: 'Instrument' },
        { id: 'human', name: 'Human Sound' },
        { id: 'ambient', name: 'Ambient Sounds' }
      ],
      samples: {
        nature: [
          { id: 'rain', emoji: '🌧️', url: '/outimage/wavplay/sounds/nature/rain.mp3' },
          { id: 'ocean', emoji: '🌊', url: '/outimage/wavplay/sounds/nature/ocean.mp3' },
          { id: 'water', emoji: '💧', url: '/outimage/wavplay/sounds/nature/water.mp3' },
          { id: 'thunder', emoji: '⚡', url: '/outimage/wavplay/sounds/nature/thunder.mp3' },
          { id: 'insect', emoji: '🦗', url: '/outimage/wavplay/sounds/nature/insect.mp3' }
        ],
        special: [
          { id: 'fireworks', emoji: '🎆', url: '/outimage/wavplay/sounds/special/fireworks.mp3' },
          { id: 'glass', emoji: '💥', url: '/outimage/wavplay/sounds/special/glass.mp3' },
          { id: 'magic', emoji: '✨', url: '/outimage/wavplay/sounds/special/magic.mp3' },
          { id: 'spaceship', emoji: '🚀', url: '/outimage/wavplay/sounds/special/spaceship.mp3' },
          { id: 'action', emoji: '💫', url: '/outimage/wavplay/sounds/special/action.mp3' }
        ],
        instrument: [
          { id: 'piano', emoji: '🎹', url: '/outimage/wavplay/sounds/instrument/piano.mp3' },
          { id: 'guitar', emoji: '🎸', url: '/outimage/wavplay/sounds/instrument/guitar.mp3' },
          { id: 'violin', emoji: '🎻', url: '/outimage/wavplay/sounds/instrument/violin.mp3' },
          { id: 'keyboard', emoji: '🎹', url: '/outimage/wavplay/sounds/instrument/keyboard.mp3' },
          { id: 'pipes', emoji: '🎵', url: '/outimage/wavplay/sounds/instrument/pipes.mp3' }
        ],
        human: [
          { id: 'baby', emoji: '👶', url: '/outimage/wavplay/sounds/human/baby.mp3' },
          { id: 'clap', emoji: '👏', url: '/outimage/wavplay/sounds/human/clap.mp3' },
          { id: 'celebrate', emoji: '🎉', url: '/outimage/wavplay/sounds/human/celebrate.mp3' },
          { id: 'footsteps', emoji: '👣', url: '/outimage/wavplay/sounds/human/footsteps.mp3' },
          { id: 'burp', emoji: '😮', url: '/outimage/wavplay/sounds/human/burp.mp3' }
        ],
        ambient: [
          { id: 'typing', emoji: '⌨️', url: '/outimage/wavplay/sounds/ambient/typing.mp3' },
          { id: 'restaurant', emoji: '🍽️', url: '/outimage/wavplay/sounds/ambient/restaurant.mp3' },
          { id: 'doorbell', emoji: '🔔', url: '/outimage/wavplay/sounds/ambient/doorbell.mp3' },
          { id: 'tv', emoji: '📺', url: '/outimage/wavplay/sounds/ambient/tv.mp3' },
          { id: 'cooking', emoji: '🍳', url: '/outimage/wavplay/sounds/ambient/cooking.mp3' }
        ]
      },
      isLoadingSample: null,
      progress: 0,
      isDragging: false,
      audioCurrentTime: 0,
      audioDuration: 0,
    }
  },
  computed: {
    currentSamples() {
      return this.samples[this.currentCategory] || []
    },
    currentTime() {
      return this.formatTime(this.$refs.audio_main?.currentTime || 0)
    },
    duration() {
      return this.formatTime(this.$refs.audio_main?.duration || 0)
    }
  },
  mounted() {
    this.generationCount = 0;
    const audio = this.$refs.audio_main
    if (audio) {
      audio.addEventListener('timeupdate', this.updateProgress)
      audio.addEventListener('loadedmetadata', this.handleAudioLoaded)
      audio.addEventListener('canplay', this.initAudioDuration)
    }
  },
  beforeUnmount() {
    const audio = this.$refs.audio_main
    if (audio) {
      audio.removeEventListener('timeupdate', this.updateProgress)
      audio.removeEventListener('loadedmetadata', this.handleAudioLoaded)
      audio.removeEventListener('canplay', this.initAudioDuration)
    }
  },
  methods: {
    async generateSound() {
      if (this.isGenerating) return;

      const audio = this.$refs.audio_main;
      if (audio && !audio.paused) {
        audio.pause();
        audio.currentTime = 0;
      }

      this.showAudioControls = false;
      this.isGenerating = true;
      this.isPlaying = false;
      
      if (!this.description?.trim()) {
        alert("please type or paste text")
        return false
      }

      const formdata = {
          text: this.description,
          email: this.email,
          subscript: this.user_subscript,
          userid: 0,
        }

      try {
        const uri = `${api_host}/sapi/gensound`
        const { data } = await axios.post(uri, formdata, {
            headers: { 
              'Content-Type': 'multipart/form-data', 
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            },      
        })
        if (data.ret === 0) {
          this.generatedSound = data.uri
          this.showAudioControls = true
          this.isPlaying = false
          this.generationCount++
        }
      } catch (error) {
        reportError(error, 'generator sound error')
        alert('An error occurred while generating the sound, please try again later!')
      } finally {
        this.isGenerating = false;
      }
    },
    async playAudio(audioElement, audioSource) {
      if (!audioElement || !audioSource) {
        reportError(new Error('Audio element or source not found'), 'playAudio');
        return;
      }
      try {
        if (!audioElement.paused) {
          await audioElement.pause();
        }
        audioElement.currentTime = 0;
        audioElement.src = `${api_host}${audioSource}`;
        
        await new Promise((resolve, reject) => {
          const loadHandler = () => {
            audioElement.removeEventListener('canplay', loadHandler);
            audioElement.removeEventListener('error', errorHandler);
            resolve();
          };
          const errorHandler = (error) => {
            audioElement.removeEventListener('canplay', loadHandler);
            audioElement.removeEventListener('error', errorHandler);
            reject(error);
          };
          audioElement.addEventListener('canplay', loadHandler);
          audioElement.addEventListener('error', errorHandler);
          audioElement.load();
        });

        await audioElement.play();
      } catch (error) {
        reportError(error, 'playAudio - play sound failed');
      }
    },
    playSound() {
      const audio = this.$refs.audio_main;
      if (!audio || !this.generatedSound) {
        reportError(new Error('Audio element or source not found'), 'playSound');
        return;
      }
      
      // 如果正在播放,则暂停
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
        // 重置音频到开始位置
        audio.currentTime = 0;
        return;
      }
      
      this.isPlayLoading = true;
      this.playAudio(audio, this.generatedSound)
        .then(() => {
          this.isPlaying = true;
          // 监听播放结束事件
          audio.onended = () => {
            this.isPlaying = false;
            audio.currentTime = 0; // 重置到开始位置
            audio.onended = null;
          };
        })
        .catch(error => {
          reportError(error, 'playSound - playback failed');
          this.isPlaying = false;
          audio.currentTime = 0; // 发生错误时也重置
        })
        .finally(() => {
          this.isPlayLoading = false;
        });
    },
    async handleDownload() {
        if (!this.generatedSound) {
          alert('There is no audio! Please generate sound first!');
          return;
        }
        if (this.isDownloading) {
          return;
        }
        
        this.isDownloading = true;
        let url;
        try {
          const response = await fetch(`${api_host}${this.generatedSound}`, {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache'
            },
            mode: 'cors',
            credentials: 'same-origin'
          });
          
          if (!response.ok) {
            reportError(new Error(`Download failed with status: ${response.status}`), 'handleDownload');
            alert('Sounds download failed, Please try later!');
            return;
          }
          
          const blob = await response.blob();
          if (!blob || blob.size === 0) {
            reportError(new Error('Invalid blob data received'), 'handleDownload');
            alert('Sounds blob download failed, Please try later!');
            return;
          }
          
          // 创建一个隐藏的下载链接
          const a = document.createElement('a');
          url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `tiktokvoice.net-${new Date().getTime()}.mp3`; // 设置文件名
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          
          // 记录下载行为
          const uri = `${api_host}/lapi/actionadd`;
          const formData = new FormData();
          formData.append('email', this.email);
          formData.append('action', 'sounds-download');
          formData.append('domain', 'tiktokvoice.net');
          await axios.post(uri, formData);
          
        } catch (error) {
          reportError(error, 'handleDownload down error');
          alert('Failed to download audio file, please try later!');
        } finally {
          // 清理 Blob URL
          if (url) {
            window.URL.revokeObjectURL(url);
          }
          this.isDownloading = false;
        }
    },
    selectPrompt(promptKey) {
      // 直接使用英文原文作为生成提示词
      this.description = promptKey.replace('soundeffect.prompt_', '');
    },
    toggleFaq(index) {
      this.$set(this.faqs[index], 'isOpen', !this.faqs[index].isOpen);
      
      this.faqs.forEach((faq, i) => {
        if (i !== index && faq.isOpen) {
          this.$set(this.faqs[i], 'isOpen', false);
        }
      });
    },
    async playSample(sample) {
      const audio = this.$refs.audio_main;
      
      // 如果正在加载,不响应点击
      if (this.isLoadingSample === sample.id) {
        return;
      }

      // 如果点击当前正在播放的音频
      if (this.currentPlaying === sample.id) {
        audio.pause();
        this.currentPlaying = null;
        return;
      }

      // 如果有其他音频在播放,先停止
      if (this.currentPlaying) {
        audio.pause();
        this.currentPlaying = null;
      }

      // 开始加载新的音频
      this.isLoadingSample = sample.id;
      
      try {
        audio.src = sample.url;
        
        // 等待音频加载完成
        await new Promise((resolve, reject) => {
          const loadHandler = () => {
            audio.removeEventListener('canplay', loadHandler);
            audio.removeEventListener('error', errorHandler);
            resolve();
          };
          const errorHandler = (error) => {
            audio.removeEventListener('canplay', loadHandler);
            audio.removeEventListener('error', errorHandler);
            reject(error);
          };
          audio.addEventListener('canplay', loadHandler);
          audio.addEventListener('error', errorHandler);
          audio.load();
        });

        // 开始播放
        await audio.play();
        this.currentPlaying = sample.id;
        
        // 监听播放结束
        audio.onended = () => {
          this.currentPlaying = null;
          audio.onended = null;
        };
        
      } catch (error) {
        console.error('播放样本失败:', error);
        this.currentPlaying = null;
      } finally {
        this.isLoadingSample = null;
      }
    },
    // 格式化当前时间
    currentTime() {
      return this.formatTime(this.$refs.audio_main?.currentTime || 0)
    },
    
    // 格式化总时长
    duration() {
      return this.formatTime(this.$refs.audio_main?.duration || 0)
    },
    
    // 格式化时间
    formatTime(seconds) {
      if (!seconds || isNaN(seconds)) return '0:00'
      const mins = Math.floor(seconds / 60)
      const secs = Math.floor(seconds % 60)
      return `${mins}:${secs.toString().padStart(2, '0')}`
    },
    
    // 更新进度条
    updateProgress() {
      if (!this.isDragging && this.$refs.audio_main) {
        const audio = this.$refs.audio_main
        this.audioCurrentTime = audio.currentTime
        this.audioDuration = audio.duration
        this.progress = (audio.currentTime / audio.duration) * 100 || 0
      }
    },
    
    // 点击进度条跳转
    seek(event) {
      const audio = this.$refs.audio_main
      if (!audio) return
      
      const container = this.$refs.progressContainer
      const rect = container.getBoundingClientRect()
      const x = event.clientX - rect.left
      const percentage = (x / rect.width) * 100
      
      audio.currentTime = (percentage / 100) * audio.duration
    },
    
    // 开始拖动
    startDrag(event) {
      this.isDragging = true
      document.addEventListener('mousemove', this.drag)
      document.addEventListener('mouseup', this.stopDrag)
    },
    
    // 拖动中
    drag(event) {
      if (!this.isDragging) return
      
      const container = this.$refs.progressContainer
      const rect = container.getBoundingClientRect()
      const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width))
      const percentage = (x / rect.width) * 100
      
      this.progress = percentage
    },
    
    // 停止拖动
    stopDrag() {
      if (!this.isDragging) return
      
      const audio = this.$refs.audio_main
      if (audio) {
        audio.currentTime = (this.progress / 100) * audio.duration
      }
      
      this.isDragging = false
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('mouseup', this.stopDrag)
    },
    // 播放音频时初始化时长
    initAudioDuration() {
      const audio = this.$refs.audio_main
      if (audio) {
        this.audioDuration = audio.duration
      }
    },

    // 在音频加载完成时设置持续时间
    handleAudioLoaded() {
      const audio = this.$refs.audio_main
      if (audio) {
        this.audioDuration = audio.duration
      }
    }
  },
  head() {
    return {
      title: this.$t('soundeffect.title'),
      keywords: this.$t('soundeffect.keywords'),
      description: this.$t('soundeffect.description'),
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/soundeffect2.css';
</style>
